<template>
  <div>
    <!-- The Viewer will be instantiated here -->
    <div :id="viewerId" class="AutodeskForgeViewer">
      <div
        id="volumeWrapper"
        class="volumeWrapper"
        v-if="isViewableLoaded === true && sizeX"
      ></div>
    </div>
    <div :id="viewerId + 'boxVolume'">
      <div class="boxVolumeWrapper" v-if="isViewableLoaded === true && sizeX">
        <div class="boxVolumeItem">
          <div class="boxVolumeLabel">X</div>
          <div class="boxVolumeData">{{ sizeX }}</div>
        </div>
        <div class="boxVolumeItem">
          <div class="boxVolumeLabel">Y</div>
          <div class="boxVolumeData">{{ sizeY }}</div>
        </div>
        <div class="boxVolumeItem">
          <div class="boxVolumeLabel">Z</div>
          <div class="boxVolumeData">{{ sizeZ }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import gql from 'graphql-tag'

// REF: https://forge.autodesk.com/developer/learn/viewer-app/understand-code
export default {
  name: 'AutodeskForgeViewer',
  props: {
    documentId: String, // Should be unpadded
    accessToken: String,
    viewerId: String,
  },
  data() {
    return {
      viewer: null,
      isViewableLoaded: false,
      unit: '',
      sizeX: 0,
      sizeY: 0,
      sizeZ: 0,
    }
  },
  methods: {
    init() {
      // REF: https://forge.autodesk.com/en/docs/viewer/v7/reference/Viewing/
      Autodesk.Viewing.DefaultSettings.reverseMouseZoomDir = true
      Autodesk.Viewing.Initializer(
        {
          env: 'AutodeskProduction',
          accessToken: this.accessToken,
        },
        () => {
          // Find the element where the 3d viewer will live.
          var htmlElement = document.getElementById(this.viewerId)
          if (htmlElement) {
            this.viewer = new Autodesk.Viewing.GuiViewer3D(htmlElement)
            this.viewer.start()
            // Load the document into the viewer.
            Autodesk.Viewing.Document.load(
              this.documentId,
              onDocumentLoadSuccess,
              onDocumentLoadFailure,
            )
          }
        },
      )

      /**
       * Autodesk.Viewing.Document.load() failuire callback.
       */
      const onDocumentLoadFailure = viewerErrorCode => {
        // REF: https://forge.autodesk.com/en/docs/viewer/v7/developers_guide/viewer_basics/initialization/#destroy-viewer-instance
        this.viewer.finish()
        this.viewer = null
        Autodesk.Viewing.shutdown()
        this.$emit('documentLoadFailed', viewerErrorCode)
      }

      const onDocumentLoadSuccess = doc => {
        // Load the default viewable geometry into the viewer.
        // Using the doc, we have access to the root BubbleNode,
        // which references the root node of a graph that wraps each object from the Manifest JSON.
        var viewable = doc.getRoot().getDefaultGeometry()
        if (viewable) {
          // Create and start the viewer in that element
          this.viewer
            .loadDocumentNode(doc, viewable)
            .then(result => {
              this.$emit('viewableLoaded')
              this.isViewableLoaded = true
              const volumnData = this.viewer.model.getBoundingBox()
              const volumnMax = volumnData.max
              const volumnMin = volumnData.min
              this.unit = this.viewer.model.getUnitString()
              this.sizeX = (volumnMax.x - volumnMin.x).toFixed(2) + this.unit
              this.sizeY = (volumnMax.y - volumnMin.y).toFixed(2) + this.unit
              this.sizeZ = (volumnMax.z - volumnMin.z).toFixed(2) + this.unit
              if (this.viewer.model.is3d()) {
                onAddPannel()
              }
            })
            .catch(err => {
              console.log('Viewable failed to load.')
              console.log(err)
            })
        }
      }
      const onAddPannel = () => {
        const ModelSizePanel = function(parentContainer, id, title, content) {
          this.content = content
          Autodesk.Viewing.UI.DockingPanel.call(
            this,
            parentContainer,
            id,
            title,
          )

          this.container.style.height = 'auto'
          this.container.style.width = 'auto'
          this.container.style.resize = 'none'
          this.container.style.left = '20px'
          this.container.style.top = '20px'
        }

        ModelSizePanel.prototype = Object.create(
          Autodesk.Viewing.UI.DockingPanel.prototype,
        )
        ModelSizePanel.prototype.constructor = ModelSizePanel

        ModelSizePanel.prototype.initialize = function() {
          this.title = this.createTitleBar('Dimension')
          this.title.style.height = '30px'
          this.title.style.fontSize = '12px'
          this.title.style.fontWeight = 'normal'
          this.container.appendChild(this.title)
          this.container.appendChild(this.content)
          this.initializeMoveHandlers(this.container)
        }
        const content = document.getElementById(this.viewerId + 'boxVolume')
        const modelSizePanel = new ModelSizePanel(
          NOP_VIEWER.container,
          'modelSizePanel',
          'Model Size',
          content,
        )
        modelSizePanel.setVisible(true)
      }
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.AutodeskForgeViewer {
  width: 100%;
  height: 100%;
  margin: 0;
}
.boxVolumeWrapper {
  color: #000000;
  background: #f8f9fa;
}
.boxVolumeItem {
  display: flex;
  align-items: center;
  padding: 4px 12px;
  min-height: 28px;
  font-size: 14px;
  & + div {
    border-top: 1px solid #dee2e6;
  }
}
.boxVolumeData {
  text-align: right;
  width: 100%;
}
.boxVolumeLabel {
  min-width: 70px;
  text-align: left;
}
</style>
<style>
.forge-spinner {
  display: none !important;
}
</style>
