








import Vue from 'vue'

export default Vue.extend({
  name: 'App',
  mounted() {
    console.log(`Version: ${process.env.VUE_APP_CAPA_VIEWER_VIEWER_VERSION}`)
    console.log(`Git SHA: ${process.env.VUE_APP_GIT_SHA}`)
  },
})
