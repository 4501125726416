




import Vue from 'vue'
import AutodeskForgeViewerWrapper from '@/components/AutodeskForgeViewerWrapper.vue' // @ is an alias to /src

export default Vue.extend({
  name: 'Viewer',
  computed: {
    fileKey() {
      return this.$route.query.fileKey
    },
  },
  components: {
    AutodeskForgeViewerWrapper,
  },
})
