<template>
  <div>
    <div v-if="accessToken && documentId">
      <AutodeskForgeViewer
        :accessToken="accessToken"
        :documentId="documentId"
        @documentLoadFailed="onDocumentLoadFailed"
        @viewableLoaded="isViewableLoaded = true"
        viewerId="CapaViewerAutodeskForgeViewer"
        :key="shouldReRender ? 'shouldReRender' : 'shouldNotReRender'"
      />
    </div>
    <div class="spinner-container" v-if="isViewableLoaded === false">
      <v-progress-circular
        class="spinner"
        :size="95"
        :width="5"
        indeterminate
        color="primary"
      ></v-progress-circular>
      <div class="text-center">
        <span class="head">
          도면 분석중...
        </span>
        <p class="mt-4 body">
          <span class="red--text">*</span>
          파일의 크기나 PC 사양에 따라
          <br />
          20초 ~ 1분 정도 시간이 걸릴 수 있습니다.
          <br />
          도면이 보이지 않아도 다음 단계로 진행 가능합니다.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import AutodeskForgeAuth from '../graphql/AutodeskForgeAuth.gql'
import TranslateModel from '../graphql/TranslateModel.gql'
import ModelTranslated from '../graphql/ModelTranslated.gql'
import AutodeskForgeViewer from '@/components/AutodeskForgeViewer.vue'

import { Base64 } from 'js-base64'

export default {
  name: 'AutodeskForgeViewerWrapper',
  data() {
    return {
      urn: null,
      autodeskForgeAuth: null,
      translation: {
        status: null,
        progress: null,
      },
      isGreedyRenderFailed: false,
      isViewableLoaded: false,
    }
  },
  apollo: {
    autodeskForgeAuth: AutodeskForgeAuth,
  },
  props: {
    fileKey: String,
  },
  computed: {
    shouldReRender() {
      return this.isGreedyRenderFailed && this.translation.status === 'SUCCESS'
    },
    accessToken() {
      return this.autodeskForgeAuth?.accessToken
    },
    guessedUrn() {
      return `urn:adsk.objects:os.object:${
        process.env.VUE_APP_CAPA_VIEWER_AUTODESK_FORGE_DATA_BUCKET_KEY_MODEL
      }/${encodeURIComponent(this.fileKey)}`.replace('%40', '@')
    },
    modelId() {
      if (!this.fileKey) {
        console.error('fileKey is invalid.')
      }
      return Base64.encode(`Model__${this.fileKey}`)
    },
    documentId() {
      const urn = this.urn || this.guessedUrn

      let base64EncodedUrn = Base64.encode(urn)
      while (base64EncodedUrn.endsWith('=')) {
        base64EncodedUrn = base64EncodedUrn.substring(
          0,
          base64EncodedUrn.length - 1,
        )
      }
      return `urn:${base64EncodedUrn}`
    },
  },
  methods: {
    onDocumentLoadFailed(viewerErrorCode) {
      console.error(`onDocumentLoadFailure() - errorCode: ${viewerErrorCode}`)
      this.isGreedyRenderFailed = true
    },
  },
  async created() {
    const {
      data: {
        translateModel: {
          id: mutationId,
          model: {
            autodeskForge: { urn, translation },
          },
        },
      },
    } = await this.$apollo.mutate({
      mutation: TranslateModel,
      variables: {
        modelId: this.modelId,
      },
    })

    // TODO: check if NOT found error
    this.urn = urn
    this.translation = translation
    if (['PENDING', 'IN_PROGRESS'].includes(translation.status)) {
      const observer = this.$apollo.subscribe({
        query: ModelTranslated,
        variables: {
          mutationId: mutationId,
        },
      })
      console.log(`The model Translation status: ${translation.status}`)
      observer.subscribe({
        next: data => {
          const {
            data: {
              modelTranslated: {
                model: {
                  autodeskForge: { urn, translation },
                },
              },
            },
          } = data
          this.urn = urn
          this.translation = translation
          if (['FAILED', 'TIMEOUT'].includes(translation.status)) {
            console.error(`The model Translation error: ${translation.status}`)
          } else {
            console.log('The model is translated.')
          }
        },
        error(error) {
          console.error(error)
        },
      })
    }
  },
  // updated() {
  //   console.log(this.autodeskForgeAuth.accessToken);
  // },
  components: {
    AutodeskForgeViewer,
  },
}
</script>

<style scoped lang="scss">
.spinner-container {
  line-height: 20px;
  .spinner {
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .head {
    font-size: 14px;
    font-weight: 700;
  }
  .body {
    font-size: 12px;
    font-weight: 400;
  }
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
</style>
